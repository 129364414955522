import "@machineq/components/css";
import "@machineq/theme/css";

import { LazyMotion } from "framer-motion";
import { setup } from "goober";
import { prefix } from "goober/prefixer";
import { createElement } from "react";
import React, { useEffect } from "react";

import { GlobalStyle } from "@pwa/components/global-style";
import { SWRProvider } from "@pwa/features/api";
import { AuthProvider } from "@pwa/features/auth";
import { type CustomApp, PageLoader } from "@pwa/features/page";
import { usePWA } from "@pwa/features/pwa";
import { initializeSentry } from "@pwa/lib/sentry";

const lazyMotionFeatures = () =>
  import("@pwa/lib/motion/motion").then((mod) => mod.domAnimation);

initializeSentry();

setup(createElement, prefix);

const App: CustomApp = ({ Component, pageProps: appPageProps }) => {
  usePWA();

  /**
   * Delete the SSR rendered styles to reduce style bloat
   * We don't really need them if the client styled
   * components is going to take over for it
   */
  useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles && jssStyles.parentNode)
      jssStyles.parentNode.removeChild(jssStyles);
  }, []);

  return (
    <AuthProvider>
      <SWRProvider>
        <PageLoader mqShouldDisplay={Component.pageProps.displayLoader} />
        {GlobalStyle()}
        <LazyMotion features={lazyMotionFeatures}>
          {Component.getLayout(<Component {...appPageProps} />, {
            ...appPageProps,
            ...Component.pageProps
          })}
        </LazyMotion>
      </SWRProvider>
    </AuthProvider>
  );
};

export default App;
